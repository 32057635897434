import React from 'react'
import { List, Form, Spin, Button} from 'antd';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { API_getnews } from '../api/post';
import logo from './../images/logo1.png';

var moment = require('moment');

const News = props => {

    const [loading, setLoading] = useState(false);

    const [newsdata, setnewsdata] = useState([]);

    const routes = [{ label: 'Главная', path: '/' }, { label: 'Новости', path: '/posts' }];

    useEffect(() => {
        getnews();
    }, []);

    const getnews = () => {
        setLoading(true);
        API_getnews().then(data => { setnewsdata(data)} )
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageBreadcrumbs routes={routes} />
            <Spin spinning={loading}>
                <Form className="che-form">
                <PageTitle title="Новости" />
                <div align="right">
                <Button icon={<PlusOutlined />} onClick={() => props.history.push('/postadd')} type="primary">
                        Добавить новость
                </Button>
                </div>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={newsdata}
                    pagination={{
                    pageSize: 5,
                    }}
                    renderItem={item => (
                        <Link to={`/post/${item.id}`}>

                    <List.Item
                        key={item.title}
                        extra={
                        <>{item.url ?
                            <img
                            width={200}
                            alt="logo"
                            src={item.url}
                            />
                            :
                            <img
                            width={200}
                            alt="logo"
                            src={logo}
                            />
                        }
                        </>
                        
                        }
                    >
                        <List.Item.Meta

                        title={<font color="#4E59F1"><a>{item.title}</a></font>}

                        description={'Добавлено:  '+ moment(item.createdate).format('DD.MM.YYYY HH:mm:ss')}
                        />
                        {item.description}
                        
                    </List.Item>
                    </Link>
                    )
                }
                />
                </Form>
            </Spin>
        </>
        )
}

export default News;