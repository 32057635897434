import React from 'react';
import { Menu, Layout } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Footer from './main/Footer';
import { PicLeftOutlined, RightSquareOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Posts from '../pages/Posts';
import Slider from '../pages/Slider';
import PostEdit from '../components/PostEdit';
import PostAdd from '../components/PostAdd';
import SliderAdd from '../components/SliderAdd';
import SliderEdit from '../components/SliderEdit';

const Main = props => {

    const [godMode, setgodMode] = useState(false);


    return (
        <div className="wrapper watermarked">
            <div className="content">
                <Header history={props.history} />
                <Layout>
                        <Menu
                            mode="horizontal"
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                            <Menu.Item key="/posts">
                                <NavLink to="/posts">
                                    <PicLeftOutlined /><span>Новости</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/sliders">
                                <NavLink to="/sliders">
                                    <RightSquareOutlined /><span>Слайдер</span>
                                </NavLink>
                            </Menu.Item>

                            {/*
                            godMode &&

                            <Menu.Item key="/admin">
                                <NavLink to="/admin">
                                    <CrownOutlined  style={{ color : "red" }} /> <span>Админ</span>
                                </NavLink>
                            </Menu.Item>
                            */}
                        </Menu>
                </Layout>   
                    <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                        <Route exact path="/" render={() => <Redirect to="/posts" />} />
                        <Switch>
                            <Route path="/posts" component={Posts} />
                            <Route path="/post/:id" component={PostEdit} />
                            <Route path="/postadd" component={PostAdd} /> 
                            <Route path="/sliders" component={Slider} />
                            <Route path="/slideradd" component={SliderAdd} />
                            <Route path="/slider/:id" component={SliderEdit} />
                        </Switch>
                    </Layout>
                
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(props => <Main {...props} />);
