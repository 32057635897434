import { message } from 'antd';

export const BeforeUpload  = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Только JPG/PNG файлы!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Файл должен быть мешьше 5MB!');
    }
    return isJpgOrPng && isLt2M;
  }
