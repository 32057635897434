import React from 'react'

const Footer = (props) => (
    <div style={{ paddingLeft: 20, paddingRight: 20, height: 100, display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '##4C4D5A', flex: '0 0 auto', color: '#B2ABAB' }}>
        <p style={{ marginRight: 20 }}>© 2024 Белый мамонт</p>

        <div>
            <p></p>
        </div>
    </div>
)

export default Footer
