import axios from './../utils/axios';
import { notification } from 'antd';

export const API_forgetpassword = (email) => {
        return axios({
            method: 'POST',
            url: `forgetpassword`,
            data: {
                email: email,
            },
        })
            .then(function(response) {
                if (response) {
                    return response.data;
                } else {
                    notification.error({ message: `Ошибка при получении данных, попробуйте позже` });
                    return [];
                }
            })
            .catch(function(error) {
            });
}