import axios from './../utils/axios';
import { notification } from 'antd';

export const API_checkreg = (email) => {
    return axios({
        method: 'POST',
        url: `checkreg`,
        data: {
            email: email,
        },
    })
        .then(function(response) {
            if (response) {
                return response.data;
            } else {
                notification.error({ message: `Ошибка` });
                return [];
            }
        })
        .catch(function(error) {
        });
};