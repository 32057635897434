import  React from 'react'
import PageTitle from './PageTitle';
import { Form, Button, Input, notification, Spin, Upload } from 'antd';
import { API_uploadfile, API_deletefile } from './../api/file';
import { API_url } from '../api/const';
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { API_updatepost , API_getpost, API_deletepost } from '../api/post';
import { LoadingOutlined, PlusOutlined, SaveOutlined , DeleteOutlined } from '@ant-design/icons';
import { BeforeUpload } from './File';

const PostEdit = (props) => {

     

    const [loading, setLoading] = useState(false);

    const [imageUrl, setimageUrl] = useState(undefined);

    const [editorState, setEditorState] = useState(undefined);

    

    useEffect(() => {
        getNewsData();
    }, []);

    const [form] = Form.useForm();

    const getNewsData = () => {
        setLoading(true);
        API_getpost(props.match.params.id)
            .then(response => {
                form.setFieldsValue({
                        newsTitle: response[0].title,
                        newsDesc: response[0].desc
                        })
                        setimageUrl(response[0].url);
                        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray((htmlToDraft(response[0].content)).contentBlocks)))
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    id: props.match.params.id,
                    title: values.newsTitle,
                    desc: values.newsDesc,
                    url: imageUrl,
                    content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                };
                API_updatepost(json)
                    .then(response => {
                        notification.success({ message: 'Данные сохранены' });
                        props.history.push('/posts');
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status === 'done') {
        var reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => {

            let fileSource = reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

            const json = {
                fileBody: fileSource,
            };

            API_uploadfile(json)
            .then(function(response) {
                setimageUrl(API_url+"/api/v1/download/"+response.IdFile)
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
        }
        setLoading(false);
        }
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };  

    const editorStyle = {
        border: '1px solid black',
        padding: '5px',
        borderRadius: '2px',
        width: '100%',
      };

    function deletepost () {
        API_deletepost(props.match.params.id)
            .then(response => {
                notification.success({ message: 'Новость удалена' });
                props.history.push('/posts');
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
            })
            .finally(() => setLoading(false));
    };


    return (
        <>
            <Form className="che-form" onFinish={handleSubmit} layout='vertical' form={form}>
                <PageTitle title={"Редактирование"}/>
                <Spin spinning={loading}>
                <Form.Item label="Название" style={{ marginBottom: 0 }} name="newsTitle" rules={[{required: true, message: 'Введите название'}]}>
                                   <Input placeholder="введите название" maxLength="250"/>
                    </Form.Item>
                    <Form.Item label="Короткое описание" style={{ marginBottom: 0 }} name="newsDesc" rules={[{required: true, message: 'Введите описание'}]}>
                                   <Input.TextArea placeholder="короткое описание" maxLength="250"/>
                    </Form.Item>
                    <Upload 
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={dummyRequest}
                        beforeUpload={BeforeUpload}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : 
                        <div>
                             {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div className="ant-upload-text">Изображение</div>
                        </div>}
                    </Upload>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorStyle={editorStyle}
                        onEditorStateChange={setEditorState}
                        toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: { 
                                previewImage: true,
                                defaultSize: {
                                height: 'auto',
                                width: '100%',
                        }},
                        }}
                    />
                    <Button icon={<SaveOutlined />} htmlType="submit" type="primary">
                                Сохранить
                    </Button>
                    <Button icon={<DeleteOutlined />} type="danger" onClick={() => deletepost()} style={{ marginRight: "10px" }} >
                                Удалить новость
                    </Button>
                </Spin>    
            </Form>
        </>
    )
}

export default PostEdit;